import { Injectable } from '@angular/core';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import {
    combineLatest,
    merge,
    Observable,
    of,
    pairwise,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
    throwError,
} from 'rxjs';
import { AUTH_PAGE_URL, ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { buildFilterStr } from '@bazis/utils';
import { debounceTime, filter, map, startWith, tap, catchError } from 'rxjs/operators';
import { EntData, EntList } from '@bazis/shared/models/srv.types';
import { BazisAnnouncementService } from '@bazis/shared/services/announcement.service';
import { validate as uuidValidate } from 'uuid';
@Injectable({
    providedIn: 'root',
})
export class AnnouncementService extends BazisAnnouncementService {
    mainTags$: Observable<EntData[]> = this.entityService
        .getEntityList$('classifier.news_tag', {
            params: {
                filter: buildFilterStr({ is_main: true }),
            },
            limit: 1000,
            saveSeparateItems: true,
        })
        .pipe(
            tap((v) => {
                v.list.forEach((item) => {
                    this.entityService.setEntity(item, item.$snapshot.slug);
                });
            }),
            map((v) => v.list),
            shareReplay(),
        );

    getNewsLink(data: EntData | any): string {
        const snapshot = data?.$snapshot ? data.$snapshot : data;
        return '/news/item/' + (snapshot.slug || snapshot.id);
    }

    getTag$(slug) {
        const params = uuidValidate(slug) ? { id: slug } : { slug };
        const haveEntity = this.entityService.getEntity('classifier.news_tag', slug);
        return haveEntity
            ? this.entityService
                  .getEntity$('classifier.news_tag', slug)
                  .pipe(catchError((e) => of(null)))
            : this.entityService
                  .getEntityList$('classifier.news_tag', {
                      params: {
                          filter: buildFilterStr(params),
                          limit: 1,
                      },
                  })
                  .pipe(
                      map((list) => {
                          if (list?.list?.length > 0) {
                              return list.list[0];
                          }
                          return null;
                      }),
                      tap((tag) => {
                          if (!tag) return;

                          this.entityService.setEntity(tag);
                          if (tag.$snapshot.slug) {
                              this.entityService.setEntity(tag, tag.$snapshot.slug);
                          }
                      }),
                  );
    }
}
