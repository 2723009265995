<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            reviews: reviews$ | async,
            index: index$ | async,
            count: count$ | async
        } as data"
    >
        <bazis-toolbar class="bh-margin-bottom-5x">
            <h6
                slot="start"
                class="bh-no-margin"
                >{{ t('review.title.h1') }}</h6
            >

            <!-- avg rating of selected organization -->
            <app-review-organization-rating
                *ngIf="data.reviews?.length > 0"
                [organizationSnapshot]="organizationSnapshot"
                [count]="data.count"
                slot="end"
            ></app-review-organization-rating>
        </bazis-toolbar>

        <ng-container *ngIf="data.reviews; else tplLoading">
            <!-- no reviews -->
            <ng-container *ngIf="data.reviews.length === 0">
                {{ t('review.organizationEmpty.title') }}<br />
                {{ t('review.organizationEmpty.description') }}
            </ng-container>

            <!-- current review -->
            <app-review-list-single-item
                *ngIf="data.reviews.length > 0"
                [entity]="data.reviews[data.index].$snapshot"
                mode="mininal"
            ></app-review-list-single-item>

            <!-- buttons when amount of reviews > 1 -->
            <div
                *ngIf="data.reviews.length > 1"
                class="bh-line-elements bh-justify-content-end"
            >
                <bazis-button
                    fill="outline"
                    [disabled]="data.index === 0"
                    (click)="index$.next(data.index - 1)"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="angle-small-left"
                    ></bazis-icon>
                </bazis-button>
                <bazis-button
                    fill="outline"
                    [disabled]="data.index === data.reviews.length - 1"
                    (click)="index$.next(data.index + 1)"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="angle-small-right"
                    ></bazis-icon>
                </bazis-button>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<!-- review skeleton -->
<ng-template #tplLoading>
    <app-review-list-single-item-skeleton></app-review-list-single-item-skeleton>
</ng-template>
