import { Injectable } from '@angular/core';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import {
    combineLatest,
    merge,
    Observable,
    of,
    pairwise,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
    throwError,
} from 'rxjs';
import { AUTH_PAGE_URL, ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { buildFilterStr } from '@bazis/utils';
import { debounceTime, filter, map, startWith, tap, catchError } from 'rxjs/operators';
import { EntList } from '@bazis/shared/models/srv.types';

@Injectable({
    providedIn: 'root',
})
export class AgroAuthService extends BazisAuthService {
    private _isFirstRedirect = true;

    updateAgencyContract$ = new Subject();

    agencyContract$ = merge(this.updateAgencyContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && role === ROLE.executor
                ? this.srv.fetchAllEntities$('agent.agency_contract', '', {
                      filter: buildFilterStr({ org_owner: organizationId }),
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('agent.agency_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updatePartnerContract$ = new Subject();

    defaultPageRedirect$ = this.role$.pipe(
        debounceTime(0),
        withLatestFrom(this.user$),
        startWith([null, null]),
        pairwise(),
        filter(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            return prevUser?.id !== currentUser?.id;
        }),
        tap(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            const regexp = /^\/(faq|about|legal\-documents){1}(\/.*)*$/;
            if (
                currentUser &&
                !currentUser.$snapshot.raw_password &&
                !regexp.test(location.pathname) &&
                currentUser.$snapshot.created_type_auth !== 'cert' &&
                currentUser.id !== prevUser?.id
            ) {
                this.router.navigate(['/login/init-password']);
                this._isFirstRedirect = false;
                return;
            }
            if (location.pathname.indexOf(AUTH_PAGE_URL) > -1 && !currentUser) return;
            this.redirectToDefaultPage(currentRole, currentUser);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    requestRolesForCompanyUser$ = combineLatest([this.roles$, this.role$]).pipe(
        map(([roles, role]) => {
            roles = roles.filter((v) => !v.$snapshot.is_system);
            if (role === ROLE.systemOperator) return roles;
            return roles.filter((v) => v.$snapshot.slug !== ROLE.director);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    private _agroChangingRole = false;

    agroChangingRole$ = this.changeRole$.pipe(
        switchMap(({ roleId, userId }) => {
            this._agroChangingRole = true;
            return this.entityService.updateEntity$(
                'authing.user',
                userId,
                {},
                {
                    role_current: roleId,
                },
            );
        }),
        withLatestFrom(this.organizationId$, this.organizationInfoId$),
        tap(([user, orgId, orgInfoId]) => {
            this._agroChangingRole = false;
            this.storageService.clearStorage([
                { entityType: 'organization.organization_info', id: orgId },
                { entityType: 'organization.organization_info', id: orgInfoId },
            ]);
            localStorage.setItem(
                'event',
                JSON.stringify({ eventName: 'roleChanged', datetime: new Date().getTime() }),
            );
            this.calculateToken(null, 'role');
            this.router.navigate(['/profile/personal-info']);
        }),
        catchError((error) => {
            this._agroChangingRole = false;
            return throwError(error);
        }),
    );

    // redirect to default page
    redirectToDefaultPage(role = null, user) {
        switch (role) {
            case ROLE.anonymous:
            case ROLE.base:
                // do not redirect from public pages to user-initial
                const regexp = /^\/(faq|about|legal\-documents|referral){1}(\/.*)*$/;
                if (!regexp.test(location.pathname)) {
                    this.router.navigate(['/user-initial']);
                }
                break;
            default:
                if (location.pathname.indexOf('access-denied') > -1 && !this._isFirstRedirect) {
                    this.router.navigate(['/']);
                } else if (
                    location.pathname.indexOf(AUTH_PAGE_URL) > -1
                    // to prevent redirect on home page when first entered page was access - denied
                ) {
                    if (user.$snapshot.roles.length === 1) {
                        this.router.navigate(['/profile/personal-info']);
                    } else this.router.navigate(['/login/select-role']);
                }
        }
        this._isFirstRedirect = false;
    }
}
