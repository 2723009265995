import { Injectable } from '@angular/core';
import { BazisChatService } from '@bazis/chat/shared/services/chat.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { OrganizationService } from '@pages/shared/services/organization.service';

@Injectable({
    providedIn: 'root',
})
export class AgroChatService extends BazisChatService {
    constructor(
        protected entityService: BazisEntityService,
        protected srvService: BazisSrvService,
        protected authService: BazisAuthService,
        protected socketService: BazisWebSocketService,
        protected organizationService: OrganizationService,
    ) {
        super(entityService, srvService, authService, socketService);
    }

    getChatContextUrl(contextModel, contextId, role = null) {
        const contextModelsToUrls = {
            'long_term.contract': (contextId) => '/contracts/' + contextId,
        };

        return contextModelsToUrls[contextModel]
            ? contextModelsToUrls[contextModel](contextId)
            : null;
    }

    openOrganizationData(organizationId) {
        this.organizationService.openRequisites(organizationId);
    }
}
