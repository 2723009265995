import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

import 'leaflet';
import { isLocal } from '@bazis/utils';

if (environment.production) {
    enableProdMode();
    if (window) {
        //window.console.log = () => {};
    }
}

if (!isLocal()) {
    Sentry.init({
        dsn: 'https://6047ad895ce1832a6e976d314e55aa3b@o4505011544260608.ingest.sentry.io/4506417026826240',
        environment: environment.production ? 'production' : 'development',
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    /^https:\/\/[0-9a-zA-Z\-]*\.agrogo\.big3\.ru/,
                    'https://agrogo.ru',
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            new Sentry.Replay(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.05,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        initialScope: {
            tags: {
                host: document.location.host,
            },
        },
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
