import { Injectable } from '@angular/core';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { buildFilterStr, mathFloatRound } from '@bazis/utils';
@Injectable({ providedIn: 'root' })
export class ReviewService {
    constructor(private entityService: BazisEntityService) {}

    calculateRating(snapshot: any) {
        const properties = ['price_score', 'quality_score', 'prof_score', 'deadline_score'];
        return mathFloatRound(
            properties.reduce((acc, current) => acc + (snapshot[current] || 0), 0) /
                properties.length,
        );
    }

    getOrganizationReviews$(orgOwner, limit = 10) {
        return this.entityService.getEntityList$('review.review', {
            params: {
                filter: buildFilterStr({ org_owner_executor: orgOwner, status: 'accepted' }),
            },
            offset: 0,
            limit,
        });
    }
}
