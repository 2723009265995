<div
    class="bazis-modal-wrap"
    *transloco="let t"
>
    <ng-container *ngIf="organization$ | async as organization">
        <div class="bazis-modal__header_shadow">
            <h3>{{ t('profile.personalInfo.modalTitle') }}</h3>
        </div>
        <div class="bazis-modal__content">
            <app-profile-requisites [organizationSnapshot]="organization"></app-profile-requisites>
            <app-profile-okveds [organizationSnapshot]="organization"></app-profile-okveds>
            <app-organization-reviews
                [organizationSnapshot]="organization"
            ></app-organization-reviews>
        </div>
        <div class="bazis-modal__footer">
            <bazis-button
                color="primary"
                (click)="close()"
                >{{ t('action.close') }}</bazis-button
            >
        </div>
    </ng-container>
</div>
