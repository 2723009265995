import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { BazisSharedModule } from '@bazis/shared/shared.module';
import { TopNotificationsComponent } from '@shared/components/top-notifications.component';
import { EntityItemMobileSkeletonComponent } from '@shared/components/skeletons/entity-item-mobile-skeleton.component';
import { NgxMaskPipe } from 'ngx-mask';
import { OpenEntityComponent } from '@shared/components/open-entity/open-entity.components';
import { ButtonPrintComponent } from '@shared/components/button-print/button-print.components';
import { SingleItemMobileComponent } from '@shared/components/single-item-mobile/single-item-mobile.component';

const DECLARATIONS = [
    TopNotificationsComponent,
    EntityItemMobileSkeletonComponent,
    OpenEntityComponent,
    ButtonPrintComponent,
    SingleItemMobileComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslocoModule,
        BazisSharedModule,
        // NgxMaskDirective,
        NgxMaskPipe,
    ],
    declarations: [...DECLARATIONS],
    exports: [BazisSharedModule, ...DECLARATIONS, NgxMaskPipe],
})
export class SharedModule {}
