import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ConfigurationService, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { ReviewService } from '@pages/shared/services/review.service';
import { EntData } from '@bazis/shared/models/srv.types';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

@Component({
    selector: 'app-organization-reviews',
    templateUrl: './reviews.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsComponent implements OnInit {
    @Input()
    organizationSnapshot: any;

    reviews$: Observable<EntData[]>;

    count$: Observable<number>;

    index$: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(private reviewService: ReviewService) {}

    ngOnInit() {
        const reviews$ = this.reviewService
            .getOrganizationReviews$(this.organizationSnapshot.org_owner.id, 100)
            .pipe(shareReplay(SHARE_REPLAY_SETTINGS));

        this.reviews$ = reviews$.pipe(map((v) => v.list));
        this.count$ = reviews$.pipe(map((v) => v.$meta.pagination.count));
    }
}
